import React from 'react'
import heroImg from '../../assets/images/nageso.jpg';
import image from '../../assets/images/image.png';
import CountUp from 'react-countup';

const Hero = () => {
  return (
    <section className='pt-0 h-screen bg-secondaryColor overflow-x-hidden ' id='home'>
        <div className="container pt-14 ">
            <div className="flex flex-col-reverse  py-6 items-center justify-between sm:flex-col md:flex-row">
                {/* ============ Hero left content========== */}

                  <div className="w-full md:basis-1/2 flex flex-col md:w-1/2 space-y-6 items-start justify-center ">
                    <h5 data-aos="fade-right " data-aos-duration="1500" className='text-primaryColor font-[600] text-[16px] '> Welcome to the digital realm of</h5>
                    <h1 className='text-headingColor text-center font-[800] text-[1.8rem] sm:text-[40px]' data-aos="fade-up" data-aos-duration="1500"> Nageso Haile </h1>
                    <h3 data-aos="fade-right " data-aos-duration="1500" className='text-primaryColor font-[600] text-[1.3rem] '> Software Developer</h3>
                    
                    <div data-aos="fade-up"  data-aos-delay= "200" data-aos-duration="1800" className="flex items-center gap-6 mt-7">
                        <a href="#contact">
                            <button  className='bg-primaryColor text-white font-[500] flex items-center gap-2 hover:bg-smallTextColor ease-in duration-300 py-2 px-4 rounded-md'>
                               <i className="ri-mail-line"></i> Contact Me Here
                            </button>
                        </a>
                        <a href='#skills' className='text-smallTextColor font-[600] text-[16px] border-b border-solid border-smallTextColor'> See skills</a>
                        
                    </div>
                    <p data-aos="fade-left " data-aos-duration="1500" className='flex gap-2 text-headingColor font-[500] text-[15px]  leading-7 sm:pl-14 sm:pr-10 mt-14' >
                        <span><i className="ri-apps-2-line"></i></span>
                        Hey, I'm Nageso, a software developer with a knack for making user-friendly apps. I specialize in Flutter and ReactJS for building cool, user-friendly interfaces. I've also dabbled in backend work using Nodejs. I enjoy turning ideas into practical software solutions
                        </p>
                        <div className="flex items-center gap-9 mt-14">
                            <span className='text-smallTextColor hover:text-primaryColor text-[15px] font-[600]'>
                                Social Network Links:
                            </span>
                            <span >
                                <a target="_blank" href="https://github.com/NagesoHaile" className='text-smallTextColor hover:text-primaryColor text-2xl font-[600]'>
                                <i class="ri-github-fill"></i>
                                </a>
                            </span>
                            <span >
                                <a target="_blank" href="https://t.me/obbonageso" className='text-smallTextColor hover:text-primaryColor text-2xl font-[600]'>
                                <i class="ri-telegram-fill"></i>
                                </a>
                            </span>
                            <span >
                                <a target="_blank" href="https://www.facebook.com/nageso.haile" className='text-smallTextColor hover:text-primaryColor text-2xl font-[600]'>
                                <i class="ri-facebook-circle-fill"></i>
                                </a>
                            </span>
                            <span >
                                <a target="_blank" href="https://www.linkedin.com/in/nageso-haile/" className='text-smallTextColor hover:text-primaryColor text-2xl font-[600]'>
                                <i class="ri-linkedin-box-fill"></i>
                                </a>
                            </span>
                            <span >
                                <a target="_blank" href="https://www.instagram.com/obbo_nageso/?igshid=MzMyNGUyNmU2YQ%3D%3D" className='text-smallTextColor hover:text-primaryColor text-2xl font-[600]'>
                                <i class="ri-instagram-fill"></i>
                                </a>
                            </span>
                        </div>
                  </div>
                  {/* ==============Hero left end ============ */}
                  {/* ==========hero image=========== */}
                  <div className="basis-1/3 mt-10 sm:mt-0">
                        <figure className='flex items-center justify-between'>
                            <img   data-aos-delay= "200" data-aos-duration="1800" src={heroImg} className='md:w-full w-3/4 h-3/4 md:h-full rounded-full pl-10 border-b border-primaryColor border-[1px]'  alt='image'/>

                        </figure>
                  </div>
            </div>
        </div>
    </section>
  )
}

export default Hero

import React from 'react'

const Footer = () => {
  const year = new Date().getFullYear()
  return (
    <footer  className="bg-[#12141e] pt-12">
         {/* ========= footer top========= */}
         <div className="container">
            <div className='sm:flex items-center justify-between md:gap-8'>
              <div className="w-full sm:w-1/2">
                  <h2 className='text-[26px] leading-10 text-white font-[600] mb-5 md:text-[2rem]'>
                    Do you want to make beautiful products?
                  </h2>
                  <a href="#contact">
                            <button  className='bg-primaryColor text-white font-[500] flex items-center gap-2 hover:bg-smallTextColor ease-in duration-300 py-2 px-4 rounded-md'>
                               <i className="ri-mail-line"></i> Hire me
                            </button>
                        </a>
              </div>
              <div className='w-full sm:w-1/2'>
                <p className="text-gray-300 leading mt-4 sm:mt-0" > Let's embark on a journey to build something that have positive impact together.</p>

                <div className="flex items-center gap-4 flex-wrap md:gap-8 mt-10">
                <span className='text-smallTextColor text-2xl font-[600]'>
                                Follow me:
                            </span>
                            <span >
                                <a href="https://github.com/NagesoHaile" className='text-primaryColor hover:text-smallTextColor text-3xl font-[600]'>
                                <i class="ri-github-fill"></i>
                                </a>
                            </span>
                            <span >
                                <a href="https://t.me/obbonageso" className='text-primaryColor hover:text-smallTextColor text-3xl font-[600]'>
                                <i class="ri-telegram-fill"></i>
                                </a>
                            </span>
                            <span >
                                <a href="https://www.facebook.com/nageso.haile" className='text-primaryColor hover:text-smallTextColor text-3xl font-[600]'>
                                <i class="ri-facebook-circle-fill"></i>
                                </a>
                            </span>
                            <span >
                                <a href="https://www.linkedin.com/in/nageso-haile/" className='text-primaryColor hover:text-smallTextColor text-3xl font-[600]'>
                                <i class="ri-linkedin-box-fill"></i>
                                </a>
                            </span>
                            <span >
                                <a href="https://www.instagram.com/obbo_nageso/?igshid=MzMyNGUyNmU2YQ%3D%3D" className='text-primaryColor hover:text-smallTextColor text-3xl font-[600]'>
                                <i class="ri-instagram-fill"></i>
                                </a>
                            </span>
                </div>
              </div>
            </div>
            <div className="menu">
          <ul className='flex items-center gap-10 mt-10'>
            <li><a className='text-gray-400 font-[600]' href="#about">About me</a></li>
            <li><a className='text-gray-400 font-[600]' href="#services">Services</a></li>
            <li><a className='text-gray-400 font-[600]' href="#skills">Portfolio</a></li>
            <li><a className='text-gray-400 font-[600]' href="#contact">Contact</a></li>
          </ul>
         </div>
         </div>
         {/* footer top end */}
         {/* footer bottom  */}
         <div className="bg-[#1b1e29] py-3 mt-14">
            <div className="container">
               <div className="flex items-center justify-center sm:justfiy-between">

                     <div className="hidden sm:block">
                        <div className="flex items-center gap-[10px]">
                           <span className='w-[35px] h-[35px] rounded-full bg-[#2b2d33] text-white font-[500] text-[18px] flex items-center justify-center'>NH</span>
                           <div className='leading-[20px]'>
                                <h2 className='text-gray-200 font-[500] text-[18px] '>Nagesso</h2>
                           </div>
                        </div>
                     </div>
                  <div>
                    <p className='text-gray-400 text-[14px] pl-8'>Copyright {year} designed by Nageso Haile- All right reserved.</p>
                  </div>
               </div>
            </div>
         </div>
         {/* footer bottom end */}
    </footer>
  )
}

export default Footer

import React from 'react'
import frontendImg from '../../assets/images/front-end.png';
import backendImg from '../../assets/images/backend.png';
import appImg from '../../assets/images/apps.png'
const Services = () => {
  return (
    <section id="services" className='bg-secondaryColor'>
        <div className="container lg:pt-5">
            <div className="text-center flex flex-col items-center justify-center">
                 <h2 className='text-headingColor font-[800] text-[2.4rem] mb-5'>What do I do?</h2>
                 <p className='lg:max-w-[600px] lg:max-auto text-headingColor font-[500] text-[16px]  text-center'>The following are the services I will offer you.</p>
            </div>
            <div className="flex flex-col justify-center sm:py-12">
                 <div className="w-full py-3 px-2 sm:max-w-lg sm:mx-auto sm:px-0" >
                    <div className='relative text-gray-700 antialiased  text-sm text-semibold'>
                        {/* ======vertical Line running through the middle */}
                         <div className='hidden absolute w-1 sm:block bg-indigo-300 h-full left-1/2 transform  -translate-x-1/2'></div>
                         {/* =========left card======== */}
                         <div className='mt-6 sm:mt-0 sm:mb-12'>
                                <div className='flex items-center flex-col sm:flex-row'>

                                    <div className="flex justify-start w-full mx-auto items-center">
                                        <div className="w-full sm:w-1/2 sm:pr-8">
                                            <div  data-aos="fade-right" data-aos-duration="1200" className="bg-white p-4 rounded shadow group hover:bg-primaryColor cursor-pointer ease-in duration-150">
                                                <h3 className='text-primaryColor  font-[700] mb-3 group-hover:text-white  group-hover:font-[500] text-2xl'>Website Development</h3>
                                                <p className='text-[15px] text-smallTextColor group-hover:text-white group-hover:font-[500]'>I can develop beautiful and attractive website with the latest technologies for your businesses, organizations and personal use based on your requirements.</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="rounded-full bg-primaryColor border-white border-4 w-10 h-10 absolute left-1/2 transform -translate-x-1/2 -translate-y-4 sm:translate-y-0 flex items justify-center ">
                                        <figure>
                                            <img src={frontendImg} alt='image' />
                                        </figure>
                                    </div>

                                </div>
                         </div>


                         {/* ==========right card ========= */}
                         <div className='mt-6 sm:mt-0 sm:mb-12'>
                                <div className='flex items-center flex-col sm:flex-row'>

                                    <div className="flex justify-end w-full mx-auto items-center">
                                        <div className="w-full sm:w-1/2 sm:pl-8">
                                            <div  data-aos="fade-right" data-aos-duration="1200" className="bg-white p-4 rounded shadow group hover:bg-primaryColor cursor-pointer ease-in duration-150">
                                                <h3 className='text-primaryColor  font-[700] mb-3 group-hover:text-white  group-hover:font-[500] text-2xl'>Backend Development</h3>
                                                <p className='text-[15px] text-smallTextColor group-hover:text-white group-hover:font-[500]'>I build robust and scalable systems that optimize workflows, automate tasks,and provide valuable insights to help you make data-driven decisions. </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="rounded-full bg-primaryColor border-white border-4 w-10 h-10 absolute left-1/2 transform -translate-x-1/2 -translate-y-4 sm:translate-y-0 flex items justify-center ">
                                        <figure>
                                            <img src={backendImg} alt='image' />
                                        </figure>
                                    </div>

                                </div>
                         </div>
                            {/* =========left card======== */}
                            <div className='mt-6 sm:mt-0 sm:mb-12'>
                                <div className='flex items-center flex-col sm:flex-row'>

                                    <div className="flex justify-start w-full mx-auto items-center">
                                        <div className="w-full sm:w-1/2 sm:pr-8">
                                            <div  data-aos="fade-right" data-aos-duration="1200" className="bg-white p-4 rounded shadow group hover:bg-primaryColor cursor-pointer ease-in duration-150">
                                                <h3 className='text-primaryColor  font-[700] mb-3 group-hover:text-white  group-hover:font-[500] text-2xl'>Mobile Application Development</h3>
                                                <p className='text-[15px] text-smallTextColor group-hover:text-white group-hover:font-[500]'>I can provide you a beautiful and interactive mobile application for your work automation,and efficiency as per your requirements.</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="rounded-full bg-primaryColor border-white border-4 w-10 h-10 absolute left-1/2 transform -translate-x-1/2 -translate-y-4 sm:translate-y-0 flex items justify-center ">
                                        <figure>
                                            <img src={appImg} alt='image' />
                                        </figure>
                                    </div>

                                </div>
                         </div>
                    </div>
                 </div>
            </div>
        </div>
    </section>
  )
}

export default Services
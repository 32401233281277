import React from 'react'
import {AiOutlineMail,AiOutlinePhone} from 'react-icons/ai'
import {CiLocationOn} from 'react-icons/ci';
import emailjs from 'emailjs-com';

const Contact = () => {
  
   const sendMail = (e)=> {
      e.preventDefault();
      emailjs.sendForm('gmail','template_0l6j54',e.target,'e6TYIlzJLnBc1nq6l')
      .then((result)=>{
         console.log(result);
      },(error)=>{
         console.log(error.text)
      })
   }

  return (
    <section id='contact' className='pb-16'>
       <h2 className='text-headingColor font-[700] text-[2.5rem] mb-8 text-center'>Get In Touch</h2>
        <div className='bg-headingColor  flex flex-col md:flex-row space-x-20 justify-center items-center p-10'>
        
        <div className='flex flex-col md:flex-row justify-center items-start md:items-center space-y-8 space-x-8 md:w-1/2 '>
                
                 <div></div>
                <div className='flex  space-x-6 items-center' >
                    
                    <h1 className='text-3xl text-gray-300 font-bold '>Contacts</h1>
                 </div>

                 <div className='flex  space-x-6 items-center' >
                    <div className='p-2 rounded-full bg-gray-500 hover:bg-primaryColor'>
                       <AiOutlinePhone size={30} className=' ' />
                    </div>
                    <a className='text-gray-300' href='+251974890490'>+251974890490</a>
                 </div>
                 <div className='flex space-x-6 items-center' >
                    <div className='p-2 rounded-full bg-gray-500 hover:bg-primaryColor'>
                       <AiOutlineMail size={30} className=' ' />
                    </div>
                    <a className='text-gray-300' href='nagesohaile23@gmail.com'>nagesohaile23@gmail.com</a>
                 </div>
                 <div className='flex space-x-6 items-center'>
                    <div className='p-2 rounded-full bg-gray-500 hover:bg-primaryColor'>
                       <CiLocationOn size={30} className=' ' />
                    </div>
                  <address className='text-gray-300'>Addis Ababa, Ethiopia</address>
                 </div>
            </div>
        </div>
      <div className="container ">
        {/* contact details */}
       
    
     
        <div className="md:flex justify-between items-center gap-8">
          <div className="w-full md:w-1/2 h-[300px] sm:h-[450px]">
          <iframe title='google-maps' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d252230.25579726827!2d38.61333128434561!3d8.96314036928755!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x164b85cef5ab402d%3A0x8467b6b037a24d49!2sAddis%20Ababa!5e0!3m2!1sen!2set!4v1696866653510!5m2!1sen!2set"  className='border-0 h-full w-full' allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
          </div>
           
        <div className="w-full mt-8 md:w-1/2 sm:h-[450px] lg:flex items-center bg-indigo-100 px:4 lg:px-8 py-8 ">
           <form onSubmit={sendMail} className='w-full' action="">
             <div className='mb-5'>
                <input name='name' type="text" className='w-full  p-3 focus:outline-none rounded-[5px]' placeholder='Enter Your Name' />
             </div>
             <div className='mb-5'>
                <input name='email' type="email" className='w-full  p-3 focus:outline-none rounded-[5px]' placeholder='Enter Your email' />
             </div>
             <div className='mb-5'>
                <input name='subject' type="text" className='w-full  p-3 focus:outline-none rounded-[5px]' placeholder='Subject' />
             </div>
             <div className='mb-5'>
                <textarea name='message' rows={3} type="text" className='w-full  p-3 focus:outline-none rounded-[5px]' placeholder='Write your message here' />
             </div>
             <button className='w-full p-3  focus:ouline-none rounded-[5px] bg-primaryColor text-white'>Send a Message</button>
           </form>
        </div>

        </div>        
        </div>
    
    </section>
  )
}

export default Contact
import React,{useRef,useEffect} from 'react'
import nageso from '../../assets/images/nageso.jpg';

const Header = () => {
    const menuRef = useRef(null);
    const headerRef = useRef(null);
     
   const stickyHeaderFunc = ()=>{
    window.addEventListener('scroll',()=>{
     if(document.body.scrollTop >80 || document.documentElement.scrollTop > 80){
      headerRef.current.classList.add('sticky_header')
     }else {
      headerRef.current.classList.remove('sticky_header')
     }
    })
   }

    useEffect(()=>{
      stickyHeaderFunc()

      return window.removeEventListener('scroll',stickyHeaderFunc)
    },[])
    const toggleMenu = () =>menuRef.current.classList.toggle('show_menu');

  return <header ref={headerRef} className='w-full h-[80px] flex items-center fixed top-0 z-10 bg-secondaryColor'>
    <div className="container">
        <div className="flex items-center justify-between">
            {/* =========logo======== */}
            <div className="flex items-center gap-[10px]">
               <span className='h-[35px] w-[35px] bg-primaryColor text-[18px] text-white font-[500] flex items-center justify-center rounded-full'><img className="rounded-full w-[35px] h-[35px]" src={nageso} /></span>
               <div className='leading-[20px]'>
               <h2 className='text-xl text-primaryColor font-[700]'>Nagesso</h2>
               {/* <p className='text-smallTextColor text-[18px] font-[700]'>Haile</p> */}
               </div>
            </div>
      {/* ===========Logo end=========== */}
      {/* =============Menu start========== */}
         <div className="menu smooth-transition duration-500" ref={menuRef} onClick={toggleMenu}>
          <ul className='flex items-center gap-10'>
          <li><a href="#home" className='text-smallTextColor font-[600]' >Home</a></li>
            <li><a href="#about" className='text-smallTextColor font-[600]' >About me</a></li>
            <li><a href="#services" className='text-smallTextColor font-[600]' >Services</a></li>
            {/* <li><a href="#portfolio" className='text-smallTextColor font-[600]' >Portfolio</a></li> */}
            <li><a href="#skills" className='text-smallTextColor font-[600]' >Skills</a></li>
            {/* <li><a href="#blogs" className='text-smallTextColor font-[600]' >Blogs</a></li> */}
            <li><a href="#contact" className='text-smallTextColor font-[600]' >Contact</a></li>
          </ul>
         </div>
      {/* ============Menu end============= */}
      {/* ==========Menu right========== */}
      <div className="flex items-center gap-4">
        <button className='flex items-center gap-2 text-smallTextColor font-[600] border border-solid border-smallTextColor py-2 px-4 rounded-md max-h-[40px] hover:bg-smallTextColor hover:text-white hover:font-[500] ease-in duration-300'>
          <a target='_blank' href="https://t.me/obbonageso"><i className='ri-send-plane-line'></i> Let's Talk</a>
        </button>
        <span onClick={toggleMenu} className='text-2xl text-smallTextColor md:hidden'><i className='ri-menu-line'></i></span>
      </div>
      {/* ===========Menu end ========== */}
        </div>
    </div>
  </header>
}

export default Header

import React,{useState,useEffect} from 'react'
import data from '../../assets/data/portfolioData';
import Modal from './Modal';

const Portfolio = () => {

    const [nextItems,setNextItems] = useState(6);
    const  [portfolios, setPortfolios] = useState(data);
    const [selectTab, setSelectTab] = useState("all");
    const [showModal,setShowModal] = useState(false);
    const [activeID,setActiveID] = useState(null)

    const  loadMoreHandler = ()=>{
        setNextItems(prev=>prev + 3);
    }
    // const showModalHandler = id => {
    //     setShowModal(true);
    //     setActiveID(id);
    // }
    useEffect(()=>{
         if(selectTab === 'all'){
            setPortfolios(data);
         }
          if(selectTab === 'web-design'){
            const filteredData = data.filter(item=> item.category === 'Web Design')
            setPortfolios(filteredData);
         }
         if(selectTab === 'mobile'){
            const filteredData = data.filter(item=> item.category === 'mobile')
            setPortfolios(filteredData);
         }
    },[selectTab])
  return (
   <section id='skills'>
   <div className='container'>
      <div className="flex items-center justify-between flex-wrap gap-2 ">
        <div className="mb-7 sm:mb-0">
            <h3 className='text-headingColor text-[2rem] font-[700]'>Skills</h3>
        </div>
        <div className="flex gab-4 ">
            <button onClick={()=>setSelectTab('all')} className='text-smallTextColor  border border-solid border-smallTextColor py-2 px-4 rounded-[8px]'>
                All
            </button>
            <button onClick={()=>setSelectTab('web-design')} className='text-smallTextColor  border border-solid border-smallTextColor py-2 px-4 rounded-[8px]'>
                Web Development
            </button>
            <button onClick={()=>setSelectTab('mobile')} className='text-smallTextColor  border border-solid border-smallTextColor py-2 px-4 rounded-[8px]'>
                Mobile App
            </button>
        </div>
      </div>
      <div className='flex items-center  justify-center gap-4  flex-wrap  mt-12'>
        {
         portfolios?.slice(0,nextItems).map((Portfolio,index) => (
             <div 
             key={index}
             data-aos="fade-zoom-in" data-aos-delay="50" data-aos-duration="1000" className='group  max-w-full sm:w-[48.5%] lg:w-[32.2%] relative z-[1]'>
                <figure>
                    <img  className='rounded-[8px]' src={Portfolio.imgUrl} alt="image" />
                </figure>
                <div className="w-full h-full bg-primaryColor bg-opacity-40 absolute top-0 left-0 z-[5] hidden group-hover:block">
                    <div className='w-full h-full flex items-center justify-center '>
                        <a href={Portfolio.webUrl}><button  className='text-white bg-headingColor hover:bg-smallTextColor py-2 px-4 rounded-[8px] font-[500] ease-in duration-200'>
                             {Portfolio.title}
                        </button></a>
                    </div>
                </div>
             </div>
         ))
        }
      </div>
       <div className='text-center mt-6'>
        {
            nextItems < portfolios.length && data.length > 6 &&
      ( <button onClick={loadMoreHandler} className='text-white bg-headingColor hover:bg-smallTextColor py-2 px-4 rounded-[8px] font-[500] ease-in duration-200'>
                            See More
                        </button>)
                        
        }
       </div>
   </div>
      {
        showModal && <Modal setShowModal={setShowModal} activeID={activeID} />
      }
   </section>
  )
}

export default Portfolio
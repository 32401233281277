import React,{useEffect,useState} from 'react';

import './App.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Hero from './components/UI/Hero';
import Services from './components/UI/Services';
import Portfolio from './components/UI/Portfolio';
import Contact from './components/UI/Contact';
import About from './components/UI/About';
import Helmet from 'react-helmet';

function App() {
  const [showButton, setShowButton] = useState(false);
  useEffect(()=>{
    AOS.init();
    window.addEventListener("scroll", () => {
      if (document.body.scrollTop >500 || document.documentElement.scrollTop > 500) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    });
  },[])

   // This function will scroll the window to the top 
   const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // for smoothly scrolling
    });
  };

  return (
    <div className='Application'>
          <Helmet>
            <meta charSet='utf-8' />
            <title>Nageso Haile- Personal Site</title>
            <meta name='description' content='Nageso Haile is Software Developer, with bachelors degree of science in Computer Science from Arbaminch University, Ethiopia. He is a software developer with a knack for making user-friendly apps,specialize in Flutter and ReactJS for building cool, user-friendly interfaces...' />
            <link rel='canonical' href='https://nageso.vercel.app/#home' />
          </Helmet>
      {showButton && (
        <button onClick={scrollToTop} className="fixed rounded-full w-[3rem] h-[3rem] bottom-10 right-5 z-50 bg-primaryColor">
          &#8679;
        </button>
      )}
     <Header/>
     <main>
       <Hero />
       <About />
       <Services />
       <Portfolio />
       <Contact />
     </main>
     <Footer />
    </div>
  );
}

export default App;
